<style type="text/css">
  @import "~@/assets/css/common.css";

  .form-control {
    padding: unset;
    border-radius: unset;
  }

  .form-control input {
    border-radius: unset;
    border: unset;
  }
</style>
<style type="text/css" scoped>
  /deep/.el-input__inner {
    height: 100%;
  }
</style>
<script>
  // import $ from "jquery";
  import LoginLeft from "@/views/pages/account/login-left";
  import LoginTop from "@/views/pages/account/login-top";
  import {
    findPassword
  } from "@/api/index.js"
  export default {
    components: {
      LoginLeft,
      LoginTop,
    },
    data() {
      const checkIdcard = (rule, value, cb) => {
        const regIdcard =
          /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
        if (regIdcard.test(value)) {
          return cb();
        }
        cb(new Error("请输入正确的身份证号"));
      };
      const checkPassword = (rule, value, cb) => {
        const Password = /^[0-9A-Za-z]{6,}$/;
        if (Password.test(value)) {
          return cb();
        }
        cb(new Error("密码格式不正确(不少于6位数字字母组合)"));
      };
      const checkPassword_again = (rule, value, cb) => {
        const regPassword = this.formDataforget2.mm
        if (regPassword == value) {
          return cb();
        }
        cb(new Error("两次密码不相同"));
      };
      return {
        formDataforget2: {
          xm: "",
          sfzjh: "",
          mm: "",
          mm_again: "",
          uuid: "",
          captchaVO: "",
        },
        rules: {
          xm: [{
            required: true,
            message: "请输入您的姓名",
            trigger: "blur",
          }, ],
          sfzjh: [{
              required: true,
              message: "请输入身份证号",
              trigger: "blur",
            },
            {
              validator: checkIdcard,
              trigger: "blur",
            },
          ],
          mm: [{
              required: true,
              message: "请输入您的密码",
              trigger: "blur",
            },
            {
              validator: checkPassword,
              trigger: "blur",
            },
          ],
          mm_again: [{
              required: true,
              message: "请再次输入您的密码",
              trigger: "blur",
            },
            {
              validator: checkPassword_again,
              trigger: "blur",
            },
          ],
        },
      };
    },
    methods: {
      checkForm: function() {
        this.$refs["formDataforget2"].validate((valid) => {
          if (valid) {
            var formData={
              xm: this.formDataforget2.xm,
              sfzjh: this.formDataforget2.sfzjh,
              mm: this.formDataforget2.mm
            }
            findPwd(formData).then(res => {
              if (res.status) {
                this.$notify({
                  title: "成功",
                  message: "找回密码成功",
                  type: "success",
                })
                // this.$router.push('/logout')
              }
            })
            return false;
          }

        });
      },
    },
  };
</script>

<template>
  <div>
    <div class="login-header">
      <div class="wp">
        <div class="p13">
          <LoginTop />
          <div class="login-bottom mt-4 d-flex">
            <LoginLeft />

            <div class="log-r">
              <p class="log-tips">设置密码</p>
              <div class="mt-4 login-main">
                <el-form :model="formDataforget2" :rules="rules" ref="formDataforget2">
                  <el-form-item prop="xm">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span id="validationTooltipUsernamePrepend" class="input-group-text icon-box"><img
                            src="@/assets/images/person/icon/user.png" alt="" /></span>
                      </div>
                      <el-input type="text" placeholder="姓名" aria-describedby="validationTooltipUsernamePrepend"
                        class="form-control h-100" v-model="formDataforget2.xm" />
                    </div>
                  </el-form-item>
                  <el-form-item prop="sfzjh">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span id="validationTooltipUsernamePrepend" class="input-group-text icon-box"><img
                            src="@/assets/images/person/icon/idcard.png" alt="" /></span>
                      </div>
                      <el-input type="text" placeholder="身份证件号" aria-describedby="validationTooltipUsernamePrepend"
                        class="form-control h-100" v-model="formDataforget2.sfzjh" />
                    </div>
                  </el-form-item>
                  <el-form-item prop="mm">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span id="validationTooltipUsernamePrepend" class="input-group-text icon-box"><img
                            src="@/assets/images/person/icon/lock.png" alt="" /></span>
                      </div>
                      <el-input id="password" type="text" placeholder="请设置新的密码"
                        aria-describedby="validationTooltipUsernamePrepend" class="form-control h-100"
                        v-model="formDataforget2.mm" />
                    </div>
                  </el-form-item>
                  <el-form-item prop="mm_again">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span id="validationTooltipUsernamePrepend" class="input-group-text icon-box"><img
                            src="@/assets/images/person/icon/completed.png" alt="" /></span>
                      </div>
                      <el-input type="text" placeholder="请重新输入一次新密码" aria-describedby="validationTooltipUsernamePrepend"
                        class="form-control h-100" v-model="formDataforget2.mm_again" />
                    </div>
                  </el-form-item>
                  <div class="login-btn">
                    <button type="button" class="btn btn-info w-100" @click="checkForm">
                      确认设置密码
                    </button>
                  </div>
                </el-form>
              </div>
            </div>
          </div>
          <div class="login-footer text-center mt-4">
            版权所有：四川省人事人才考试测评基地
<!--            © 2021 Rights Reserved-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
